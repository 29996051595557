<template>
	<div class="content_out">
		<div v-if="!isUniversity && !isDirection">
			<!-- 顶部背景图 -->
			<div class="country_bg_box">
				<img
					class="country_bg_img"
					:src="countryData.backImg1"
					mode="widthFix"
					alt=""
				/>
				<div class="country_title flexVerticalCenter">
					<div>{{ countryData.title }}</div>
					<div>{{ countryData.etitle }}</div>
				</div>
			</div>
			<!-- 留学学校+方向 -->
			<div class="country_school_direction_box">
				<div class="country_school_box">
					<div class="country_school">
						<div class="country_title_box flexVerticalCenter">
							{{ countryData.name }}名校推荐
							<div class="country_title_border"></div>
						</div>
						<div class="country_school_desc">
							{{ countryData.desc }}
						</div>
						<div class="country_school_list_out_out flexBetween">
							<div
								class="country_school_btn_box"
								@click="fnPrev()"
								v-if="canScroll"
							>
								<img
									v-if="currentClickNumber > 1"
									class="btn_active_icon"
									src="https://qn.juquedu.com/left_active_icon.png"
									alt=""
								/>
								<img
									v-else
									class="btn_active_icon"
									src="https://qn.juquedu.com/left_icon.png"
									alt=""
								/>
							</div>
							<div class="country_school_list_out flexBetween">
								<div
									class="country_school_list_box"
									:ref="`fixedBox`"
								>
									<div
										class="country_school_list flexCenter"
										:style="
											`width:${widthSum}px;transform:translate(${scrollResultWidth}px,0);transition:0.7s;`
										"
									>
										<div
											class="country_school_imgs flexVerticalCenter"
											v-for="(item,
											d) in countryData.schoolList"
											:key="d + 't'"
										>
											<img
												style="border-radius:16px;width:100%;height:100%"
												:src="item.img1"
												alt=""
											/>
											<div
												class="country_school_imgs_bg_box"
											>
												<div
													class="country_school_imgs_bg"
												></div>
												<div
													class="country_school_details flexCenter"
												>
													<div
														class="country_school_name"
													>
														{{ item.name }}
													</div>
													<div
														class="country_school_des"
													>
														{{ item.ename }}
													</div>
													<div
														class="country_school_introduce"
														v-html="item.desc"
													></div>
													<div
														class="country_school_btn flexVerticalCenter"
														@click="
															toPage(
																item,
																'university'
															)
														"
													>
														了解更多
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								class="country_school_btn_box"
								@click="fnNext()"
								v-if="canScroll"
							>
								<img
									v-if="
										noScrollRight &&
											countryData.schoolList.length > 2
									"
									class="btn_active_icon"
									src="https://qn.juquedu.com/right_active_icon.png"
									alt=""
								/>
								<img
									v-else
									class="btn_active_icon"
									src="https://qn.juquedu.com/right_icon.png"
									alt=""
								/>
							</div>
						</div>
						<div class="country_title_box flexVerticalCenter">
							{{ countryData.name }}留学方向
							<div class="country_title_border"></div>
						</div>
					</div>
				</div>
				<div
					class="country_direction_box flexVerticalCenter"
					:style="{
						backgroundImage: 'url(' + countryData.img1 + ')'
					}"
				>
					<div
						class="country_school"
						style="position:absolute;top:-70px"
					>
						<div class="country_school_list_out_out flexBetween">
							<div
								class="country_school_btn_box"
								@click="fnPrev2()"
								v-if="canScroll2"
							>
								<img
									v-if="currentClickNumber2 > 1"
									class="btn_active_icon"
									src="https://qn.juquedu.com/left_active_icon.png"
									alt=""
								/>
								<img
									v-else
									class="btn_active_icon"
									src="https://qn.juquedu.com/left_icon.png"
									alt=""
								/>
							</div>
							<div class="country_school_list_out flexBetween">
								<div
									class="country_school_list_box"
									:ref="`fixedBox2`"
								>
									<div
										class="country_school_list flexCenter"
										:style="
											`width:${widthSum2}px;transform:translate(${scrollResultWidth2}px,0);transition:0.7s;`
										"
									>
										<div
											class="country_direction_bg"
											v-for="(item,
											d) in countryData.directionList"
											:key="d + 't'"
											:style="{
												backgroundImage:
													'url(' +
													getLoopBg(d, 'bg') +
													')'
											}"
										>
											<div class="country_direction_name">
												{{ item.name }}
											</div>
											<div
												class="country_direction_introduce"
												v-html="item.desc"
											></div>
											<img
												class="country_direction_btn"
												:src="getLoopBg(d, 'btn')"
												@click="
													toPage(item, 'direction')
												"
												alt=""
												srcset=""
											/>
										</div>
									</div>
								</div>
							</div>
							<div
								class="country_school_btn_box"
								@click="fnNext2()"
								v-if="canScroll2"
							>
								<img
									v-if="
										noScrollRight2 &&
											countryData.directionList.length > 3
									"
									class="btn_active_icon"
									src="https://qn.juquedu.com/right_active_icon.png"
									alt=""
								/>
								<img
									v-else
									class="btn_active_icon"
									src="https://qn.juquedu.com/right_icon.png"
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 留学申请条件 -->
			<div class="country_apply_box">
				<div class="country_title_box flexVerticalCenter">
					留学申请条件
					<div class="country_title_border"></div>
				</div>
				<div class="country_apply">
					<div
						class="country_apply_item"
						v-for="(item, i) in countryData.applyList"
						:key="i"
					>
						<div class="country_apply_item_title">
							{{ item.name }}
						</div>
						<div class="country_apply_item_border"></div>
						<div class="country_apply_item_desc">
							{{ item.desc }}
						</div>
					</div>
				</div>
			</div>
			<!-- 案例展示 -->
			<div class="country_case_box">
				<div class="country_title_box flexVerticalCenter">
					案例展示
					<div class="country_title_border"></div>
				</div>
				<div class="country_case flexVerticalCenter">
					<div
						class="country_case_item"
						v-for="(item, i) in countryData.studentList"
						:key="i"
						v-show="i <= 2"
					>
						<div class="country_case_item_info flexCenter">
							<img
								class="country_case_item_info_img"
								:src="item.img1"
								alt=""
								srcset=""
							/>
							<div class="country_case_item_info_name">
								{{ item.name }}
								<div class="country_case_item_info_school">
									{{ item.school }}
								</div>
							</div>
						</div>
						<div class="country_case_item_desc">
							{{ item.desc }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="isUniversity && !isDirection">
			<!-- 顶部背景图 -->
			<div class="country_bg_box">
				<img
					class="country_bg_img"
					:src="universityData.img1"
					mode="widthFix"
					alt=""
				/>
				<div class="country_title flexVerticalCenter">
					<div>{{ universityData.name }}</div>
					<div>{{ universityData.ename }}</div>
				</div>
			</div>
			<div class="university_details">
				<div class="country_title2 flexVerticalCenter">
					院校介绍
				</div>
				<span
					class="university_details_desc"
					v-html="universityData.desc"
				></span>
				<div class="country_title2 flexVerticalCenter">
					城市介绍
				</div>
				<div
					class="university_details_desc"
					v-html="universityData.cityDesc"
				></div>
			</div>
		</div>
		<div v-if="!isUniversity && isDirection">
			<div class="university_details university_details_bg">
				<div class="university_details" style="padding:0;">
					<div
						class="country_title2 flexVerticalCenter"
						style="padding:198px 0 30px;margin:0;"
					>
						<!-- 
						style="padding:198px 0 60px;margin:0;" -->
						{{ directionData.name }}
					</div>
					<span
						class="university_details_desc"
						v-html="directionData.desc"
					></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			countryData: {
				applyList: [],
				schoolList: [],
				directionList: [],
				studentList: []
			},
			scrollResultWidth: 0, //transform滚动的距离
			signleWidth: 614, //单个流程的宽度
			currentClickNumber: 1,
			noScrollRight: true,
			canScroll: true,
			widthSum: 1200,
			scrollResultWidth2: 0, //transform滚动的距离
			signleWidth2: 410, //单个流程的宽度
			currentClickNumber2: 1,
			noScrollRight2: true,
			canScroll2: true,
			widthSum2: 1200,
			isUniversity: false,
			universityData: {},
			isDirection: false,
			directionData: {}
		}
	},
	watch: {
		$route: {
			// $route可以用引号，也可以不用引号
			handler() {
				if (this.$route.query.countryId) {
					var countryId = this.$route.query.countryId
					if (sessionStorage.getItem(countryId + 'CountryData')) {
						this.countryData = JSON.parse(
							sessionStorage.getItem(countryId + 'CountryData')
						)
						this.setCountryData()
					} else {
						this.getData(this.$route.query.countryId)
					}
				} else {
					this.$router.push({
						path: '/home'
					})
				}
			},
			deep: true, // 深度观察监听
			immediate: true // 第一次初始化渲染就可以监听到
		}
	},
	created() {},
	methods: {
		//点击上一个
		fnPrev() {
			//如果右点击的次数大于1，才可以左滚
			if (this.currentClickNumber > 1) {
				this.currentClickNumber -= 1
				this.noScrollRight = true
				this.fnScrollWidth('reduce')
			} else {
				return false
			}
		},
		//点击下一个
		fnNext() {
			const currentScrollWidth = this.$refs[`fixedBox`].clientWidth
			const canNumber = Math.floor(currentScrollWidth / this.signleWidth) //可以放下的个数
			//如果最后一个流程图标已经展示出来，则停止滚动
			if (
				this.currentClickNumber + canNumber >=
				this.countryData.schoolList.length
			) {
				return
			}
			//说明放不下有滚动条
			if (this.countryData.schoolList.length > canNumber) {
				this.currentClickNumber += 1
				if (
					this.currentClickNumber + canNumber >=
					this.countryData.schoolList.length
				) {
					this.noScrollRight = false
				}
				this.fnScrollWidth('add')
			}
		},
		//translate的宽度
		fnScrollWidth(type) {
			let result = 0
			if (type === 'reduce') {
				result = 614
			} else if (type === 'add') {
				result = -614
			} else {
				result = 0
			}
			this.scrollResultWidth += result
		},
		// 循环背景图
		getLoopBg(index, type) {
			var bg = ''
			var btn = ''
			if ((index + 1) % 10 == 1 || (index + 1) % 10 == 4) {
				bg = 'country_direction_item_bg1.png'
				btn = 'country_direction_item_icon1.png'
			} else if ((index + 1) % 10 == 2 || (index + 1) % 10 == 5) {
				bg = 'country_direction_item_bg2.png'
				btn = 'country_direction_item_icon2.png'
			} else {
				bg = 'country_direction_item_bg3.png'
				btn = 'country_direction_item_icon3.png'
			}
			var bg2 = 'https://qn.juquedu.com/' + bg
			var btn2 = 'https://qn.juquedu.com/' + btn
			if (type == 'bg') {
				return bg2
			} else {
				return btn2
			}
		},
		//点击上一个
		fnPrev2() {
			//如果右点击的次数大于1，才可以左滚
			if (this.currentClickNumber2 > 1) {
				this.currentClickNumber2 -= 1
				this.noScrollRight2 = true
				this.fnScrollWidth2('reduce')
			} else {
				return false
			}
		},
		//点击下一个
		fnNext2() {
			const currentScrollWidth = this.$refs[`fixedBox2`].clientWidth
			const canNumber = Math.floor(currentScrollWidth / this.signleWidth2) //可以放下的个数
			//如果最后一个流程图标已经展示出来，则停止滚动
			if (
				this.currentClickNumber2 + canNumber >=
				this.countryData.directionList.length
			) {
				return
			}
			//说明放不下有滚动条
			if (this.countryData.directionList.length > canNumber) {
				this.currentClickNumber2 += 1
				if (
					this.currentClickNumber2 + canNumber >=
					this.countryData.directionList.length
				) {
					this.noScrollRight2 = false
				}
				this.fnScrollWidth2('add')
			}
		},
		//translate的宽度
		fnScrollWidth2(type) {
			let result = 0
			if (type === 'reduce') {
				result = 410
			} else if (type === 'add') {
				result = -410
			} else {
				result = 0
			}
			this.scrollResultWidth2 += result
		},
		// 跳转
		toPage(item, type) {
			var query = {
				countryId: item.countryId
			}
			if (type == 'university') {
				query.universityId = item.id
			} else {
				query.directionId = item.id
			}
			this.$router.push({
				path: '/country',
				query: query
			})
		},
		// 设置值
		setCountryData() {
			var width = this.signleWidth * this.countryData.schoolList.length
			if (width > 1200) {
				this.widthSum = width
			}
			var width2 =
				this.signleWidth * this.countryData.directionList.length
			if (width2 > 1200) {
				this.widthSum2 = width2
			}
			console.log('countryData', this.countryData)
			if (this.$route.query.universityId) {
				this.isUniversity = true
				this.isDirection = false
				this.universityData = this.countryData.schoolList.filter(
					(item) => item.id == this.$route.query.universityId
				)[0]
				console.log('universityData', this.universityData)
			} else {
				this.isUniversity = false
			}
			if (this.$route.query.directionId) {
				this.isDirection = true
				this.isUniversity = false
				this.directionData = this.countryData.directionList.filter(
					(item) => item.id == this.$route.query.directionId
				)[0]
				console.log('directionData', this.directionData)
			} else {
				this.isDirection = false
			}
		},
		// 获取数据
		async getData(countryId) {
			var params = new URLSearchParams()
			params.append('sort', 'sort,asc')
			const { data: res } = await this.$http.post(
				'hwapp/juquHwWwwPage/un/get',
				params
			)
			if (res.status == 1001) {
				sessionStorage.setItem('qrurl', res.data.qrurl)
				this.countryData = res.data.countryList.filter(
					(item) => item.id == countryId
				)[0]
				this.setCountryData()
			}
		}
	},
	beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
.content_out {
	overflow-x: hidden;
}
// 顶部背景图
.country_bg_box {
	width: 100%;
	position: relative;
	.country_bg_img {
		min-width: 1200px;
		width: 100%;
		display: block;
	}
	.country_title {
		color: #fff;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		flex-direction: column;

		div:nth-child(1) {
			font-size: 72px;
			font-weight: 800;
		}
		div:nth-child(2) {
			font-size: 30px;
			font-weight: 300;
			margin-top: 9px;
		}
	}
}
// 标题
.country_title_box {
	font-size: 48px;
	font-weight: bold;
	color: #333333;
	flex-direction: column;
	.country_title_border {
		width: 43px;
		height: 6px;
		background: #4d4dd9;
		margin-top: 30px;
	}
}
// 标题2
.country_title2 {
	font-size: 48px;
	font-family: PingFang SC-Bold, PingFang SC;
	font-weight: bold;
	color: #333333;
}
.country_title2::before {
	content: '';
	display: inline-block;
	width: 6px;
	height: 20px;
	background: #333333;
	margin-right: 21px;
}
.country_title2::after {
	content: '';
	display: inline-block;
	width: 6px;
	height: 20px;
	background: #333333;
	margin-left: 21px;
}
// 留学学校+方向
.country_school_direction_box {
	width: 100%;
	overflow: hidden;
	.country_school_box {
		width: 100%;
		height: 956px;
		position: relative;
		background-image: url('https://qn.juquedu.com/country_school_bg.png');
		background-size: cover;
		background-position: center center;
		padding: 80px 0 0;
		box-sizing: border-box;
	}
	.country_school {
		width: 1200px;
		margin: 0 auto;
		.country_school_desc {
			font-size: 24px;
			font-weight: 400;
			color: #999999;
			line-height: 40px;
			text-align: center;
			padding: 20px 0 60px;
		}
	}
	.country_school_list_out_out {
		width: 1270px;
		margin: 0 auto 74px;
		position: relative;
		.country_school_list_out {
			width: 1200px;
			margin: 0 auto;
			position: relative;
			.country_school_list_box {
				width: 100%;
				flex: 1;
				overflow: hidden;
			}
			.country_school_list {
				white-space: nowrap;
				.country_school_imgs {
					width: 586px;
					height: 311px;
					margin-right: 28px;
					border-radius: 20px;
					cursor: pointer;
					position: relative;
					.country_school_imgs_bg_box {
						width: 100%;
						height: 100%;
						display: none;
						border-radius: 16px;
						position: absolute;
						padding: 0 30px;
						box-sizing: border-box;
						.country_school_imgs_bg {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							background: #000000;
							opacity: 0.42;
							border-radius: 16px;
						}
						.country_school_details {
							width: 100%;
							height: 100%;
							position: relative;
							text-align: center;
							flex-direction: column;
							position: relative;
							.country_school_name {
								font-size: 30px;
								font-weight: 400;
								color: #ffffff;
								padding: 30px 0 8px;
							}
							.country_school_des {
								font-size: 20px;
								font-weight: 400;
								color: #d4d4d4;
							}
							.country_school_introduce {
								width: 100%;
								font-size: 20px;
								font-weight: 400;
								color: #ffffff;
								white-space: pre-line;
								// padding: 20px 0;
								display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
								-webkit-line-clamp: 3; /*显示的行数；如果要设置2行加...则设置为2*/
								overflow: hidden; /*超出的文本隐藏*/
								text-overflow: ellipsis; /* 溢出用省略号*/
								-webkit-box-orient: vertical; /*伸缩盒子的子元素排列：从上到下*/
							}
							.country_school_btn {
								width: 88px;
								height: 33px;
								font-size: 15px;
								font-weight: 400;
								color: #333333;
								background: #ffffff;
								border-radius: 28px;
								position: absolute;
								bottom: 30px;
							}
						}
					}
				}
				.country_school_imgs:hover {
					.country_school_imgs_bg_box {
						display: block;
						animation: fadeInUp 0.5s 1;
					}
				}
				.country_school_imgs:last-child {
					margin-right: 0;
				}
			}
		}
		.country_school_btn_box {
			width: 70px;
			height: 70px;
			position: absolute;
			z-index: 2;
			.btn_icon {
				width: 100%;
				height: 100%;
			}
			.btn_active_icon {
				width: 100%;
				height: 100%;
				cursor: pointer;
			}
		}
		.country_school_btn_box:nth-child(1) {
			left: 0;
		}
		.country_school_btn_box:last-child {
			right: 0;
		}
	}
	.country_direction_box {
		width: 100%;
		height: 409px;
		position: relative;
		background-size: cover;
		background-position: center center;
		.country_direction_bg {
			width: 381px;
			height: 408px;
			margin-right: 29px;
			background-size: cover;
			padding: 50px 30px 80px;
			box-sizing: border-box;
			position: relative;
			color: #333333;
			.country_direction_name {
				font-size: 30px;
				font-weight: bold;
				// padding-bottom: 24px;
			}
			.country_direction_introduce {
				width: 100%;
				font-size: 20px;
				font-weight: 400;
				white-space: pre-line;
				display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
				-webkit-line-clamp: 6; /*显示的行数；如果要设置2行加...则设置为2*/
				overflow: hidden; /*超出的文本隐藏*/
				text-overflow: ellipsis; /* 溢出用省略号*/
				-webkit-box-orient: vertical; /*伸缩盒子的子元素排列：从上到下*/
			}
			.country_direction_btn {
				width: 40px;
				height: 40px;
				position: absolute;
				right: 30px;
				bottom: 30px;
				cursor: pointer;
			}
		}
		.country_school_imgs:hover {
			.country_school_imgs_bg_box {
				display: block;
				animation: fadeInUp 0.5s 1;
			}
		}
		.country_school_imgs:last-child {
			margin-right: 0;
		}
	}
}
// 留学申请条件
.country_apply_box {
	width: 100%;
	height: 929px;
	position: relative;
	background-image: url('https://qn.juquedu.com/country_apply_bg.png');
	background-size: cover;
	background-position: center center;
	padding: 40px 0 70px;
	box-sizing: border-box;
	.country_apply {
		width: 1200px;
		margin: 60px auto 0;
		display: table;
		.country_apply_item {
			width: 586px;
			height: 314px;
			float: left;
			margin: 0 28px 28px 0;
			padding: 40px 30px;
			box-sizing: border-box;
			background-image: url('https://qn.juquedu.com/country_apply_item_bg1.png');
			background-size: cover;
			.country_apply_item_title {
				font-size: 30px;
				font-weight: 800;
				color: #333333;
			}
			.country_apply_item_border {
				width: 210px;
				height: 4px;
				background: #c4b8f8;
				margin: 20px 0 30px;
			}
			.country_apply_item_desc {
				font-size: 20px;
				font-weight: 400;
				color: #333333;
			}
		}
		.country_apply_item:nth-child(2) {
			background-image: url('https://qn.juquedu.com/country_apply_item_bg2.png');
			margin-right: 0;
			.country_apply_item_border {
				background: #9c8ee1;
			}
		}
		.country_apply_item:nth-child(3) {
			background-image: url('https://qn.juquedu.com/country_apply_item_bg3.png');
			margin-bottom: 0;
			.country_apply_item_border {
				background: #8097e8;
			}
		}
		.country_apply_item:nth-child(4) {
			background-image: url('https://qn.juquedu.com/country_apply_item_bg4.png');
			margin: 0;
			.country_apply_item_border {
				background: #8fa3f2;
			}
		}
	}
}
// 案例展示
.country_case_box {
	width: 100%;
	height: 805px;
	position: relative;
	background-image: url('https://qn.juquedu.com/country_case_bg.png');
	background-size: cover;
	background-position: center center;
	padding: 50px 0 115px;
	box-sizing: border-box;
	.country_case {
		width: 1200px;
		margin: 60px auto 0;
		.country_case_item {
			width: 381px;
			height: 477px;
			background: #f6f8ff;
			border-radius: 20px;
			border: 2px solid #7878e2;
			margin-right: 27px;
			padding: 24px;
			box-sizing: border-box;
			.country_case_item_info {
				border-bottom: 2px dashed #ababff;
				padding-bottom: 28px;
				margin-bottom: 30px;
				.country_case_item_info_img {
					width: 84px;
					height: 84px;
					border-radius: 50%;
					margin-right: 24px;
				}
				.country_case_item_info_name {
					font-size: 30px;
					font-weight: bold;
					color: #333333;
					.country_case_item_info_school {
						width: 100%;
						font-size: 20px;
						font-weight: 400;
						padding-top: 8px;
						white-space: pre-line;
						display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
						-webkit-line-clamp: 1; /*显示的行数；如果要设置2行加...则设置为2*/
						overflow: hidden; /*超出的文本隐藏*/
						text-overflow: ellipsis; /* 溢出用省略号*/
						-webkit-box-orient: vertical; /*伸缩盒子的子元素排列：从上到下*/
					}
				}
			}
			.country_case_item_desc {
				width: 100%;
				font-size: 20px;
				font-weight: 400;
				color: #666666;
				white-space: pre-line;
				display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
				-webkit-line-clamp: 8; /*显示的行数；如果要设置2行加...则设置为2*/
				overflow: hidden; /*超出的文本隐藏*/
				text-overflow: ellipsis; /* 溢出用省略号*/
				-webkit-box-orient: vertical; /*伸缩盒子的子元素排列：从上到下*/
			}
		}
		.country_case_item:last-child {
			margin: 0;
		}
	}
}
// 学校详情
.university_details {
	width: 1200px;
	margin: 0 auto;
	padding-bottom: 140px;
	.country_title2 {
		// margin: 80px 0 40px;
		margin: 50px 0 10px;
	}
	.university_details_desc {
		font-size: 24px;
		font-weight: 400;
		color: #333333;
		line-height: 42px;
		white-space: pre-line;
	}
}
.university_details_bg {
	width: 100%;
	min-height: 483px;
	// padding-bottom: 120px;
	padding-bottom: 90px;
	background: #eaefff;
}
</style>
